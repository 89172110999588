<template>
  <div class="column has-text-centered ">
    <div class="title is-3" v-html="pageData.fields.title" v-if="pageData.fields.title"></div>
    <div class="title is-4" v-html="pageData.fields.subtitle" v-if="pageData.fields.subtitle"></div>
    <div class="container has-space-below">
      <figure class="image is-inline-block" v-if="pageData.fields.panelImage">
        <img
            :sizes="'(max-width: 768px) calc((100vw - 64px) / 3), calc((852px - '+(size - 1)+' * 30px) / 6)'"
            :srcset="`${pageData.fields.panelImage.fields.file.url}?w=320&h=320&fit=scale 320w,
                      ${pageData.fields.panelImage.fields.file.url}?w=640&h=640&fit=scale 640w,
                      ${pageData.fields.panelImage.fields.file.url}?w=960&h=960&fit=scale 960w`"
            :src="pageData.fields.panelImage.fields.file.url + '?w=640&h=640&fit=scale'"
            :alt="pageData.fields.panelImage.fields.description"
        >
      </figure>
    </div>
<!--    <div class="content genpanel" v-html="pageData.fields.htmlText"></div>-->
    <rich-text :document="pageData.fields.richText" class="genpanel" :background="background"></rich-text>
    <div>
      <template v-if="validURL">
        <button :class="buttonClass"
                :href="pageData.fields.url">
          <span>{{ pageData.fields.buttonTitle }}</span>
          <span class="buttonicon">
          <font-awesome-icon  :icon="['fal', 'external-link-alt']" />
        </span>
        </button>
      </template>
      <template v-else-if="pageData.fields.buttonTitle">
        <router-link tag="button"
                     :to="pageData.fields.to"
                     :class="buttonClass">
          {{ pageData.fields.buttonTitle }}
        </router-link>
      </template>
    </div>
<!--    <div class="has-space-below"></div>-->
  </div>
</template>

<script>
  import RichText from '@/components/RichText.vue'

  export default {
    name: 'IconPanel',
    props: {
      pageData: Object,
      rootPageData: Object,
      background: String,
      size: Number
    },
    components: {
      RichText
    },
    computed: {
      buttonClass() {
        //console.log("[buttonClass] this.rootPageData:", JSON.stringify(this.rootPageData, '', 2));
        let c = "button is-outlined is-radiusless is-uppercase ";
        //console.log("[buttonClass] this.background:", this.background);
        if (this.rootPageData.fields.background !== 'has-background-white') {
          c = c + ' is-inverted is-danger ';
        }
        return c;
      },
      //Use the URL rather than the "To" state. todo a bit more validation that this is a valid URL
      validURL() {
        let validity = ((typeof this.pageData.fields.url !== 'undefined') &&
          this.pageData.fields.url !== null &&
          this.pageData.fields.url !== "");
        this.$log.debug("{ButtonPanel} fields.url validity: ", JSON.stringify(validity, '', 4));
        return validity;
      }
    }

  }
</script>


<!--DOM content created with v-html are not affected by scoped styles, but you can still style them using deep selectors.-->
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
  //the first three to correct a bug in Bulma version 8
  @import "~bulma/sass/utilities/functions";
  @import "~bulma/sass/utilities/initial-variables";
  @import "~bulma/sass/utilities/derived-variables";
  @import "~bulma/sass/utilities/mixins";
  @import "@/scss/_bulmaOverridden";

  div.genpanel {
    height: 50%;
  }


</style>
